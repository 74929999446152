import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect, useState } from 'react';
import { locationService } from '../../api/locations/location.service';
import { LocationAssetModel } from '../../types/LocationAssetModel';
import { AssetsAtLocationGrid } from './AssetsAtLocationGrid';
import { useParams, useSearchParams } from 'react-router-dom';
import { DisplayMode } from '@/types/display-mode.enum';
import LocationHierarchyToggleButton from '../LocationHierarchyToggleButton';
import { PagePaginationResultDto, PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import PaginationControls from '@/components/grid/PaginationControls';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import { useTranslation } from '@/lib';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import AssetTypesFilter from '@/components/filterbar/filters/AssetTypesFilter';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import { GridApi } from 'ag-grid-community';
import dayjs from 'dayjs';
import { get } from 'http';

const ASSET_FETCH_LIMIT = 1000;

export const LocationAssetsTab: FunctionComponent = () => {
  const { locationId } = useParams();
  if (!locationId) {
    return;
  }

  const { t } = useTranslation();
  const { exportToExcel } = useExportToExcel();

  const locationIdNumber = Number.parseInt(locationId);

  const [searchParams, setSearchParams] = useSearchParams();
  const { setFiltersToUrl, filters } = useQueryParamsFilters(
    {
      pageNumber: searchParams.get('pageNumber') ? Number.parseInt(searchParams.get('pageNumber')!) : 1,
    },
    CacheKey.LOCATION_ASSET_FILTERS,
  );

  const [selectedRows, setSelectedRows] = useState<LocationAssetModel[]>([]);

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);

  const {
    data: assetData,
    isLoading: assetIsLoading,
    isError: assetIsError,
    fetchData: fetchAssets,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<LocationAssetModel>>(() => getAssetsIndividual());

  const onAssetsMoved = () => {
    fetchAssets();
  };

  const getAssetsHierarchical = (page?: number) => {
    return locationService.getAssetsHierarchical(
      {
        locationId: filters.current.locationId ?? locationIdNumber,
      },
      {
        page: page ?? filters.current.pageNumber,
        limit: ASSET_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
      {
        assetTypeIds: filters.current.assetTypeIds as number[],
      },
    );
  };

  const getAssetsIndividual = (page?: number) => {
    return locationService.getAssets(
      {
        locationId: filters.current.locationId ?? locationIdNumber,
      },
      {
        page: page ?? filters.current.pageNumber,
        limit: ASSET_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
      {
        assetTypeIds: filters.current.assetTypeIds as number[],
      },
    );
  };

  function getAssets(displayMode: DisplayMode, page?: number) {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      return getAssetsHierarchical(page);
    } else {
      return getAssetsIndividual(page);
    }
  }

  useEffect(() => {
    setApiCallArg(() => getAssets(displayMode));
  }, [displayMode]);

  function clearSelection() {
    setSelectedRows([]);
  }

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    clearSelection();

    setApiCallArg(() => getAssets(displayMode));
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  const onSortChange = (sortOption?: PageSortOption, sortDirection?: PageSortOrder) => {
    handleSearch({
      ...filters.current,
      sortOption,
      sortDirection,
    });
  };

  async function onExportToExcelClicked(gridApi: GridApi<LocationAssetModel> | undefined) {
    if (gridApi) {
      const data: LocationAssetModel[] = [];
      const dataPage1 = await getAssets(displayMode, 1);

      if (dataPage1.isSuccess) {
        data.push(...dataPage1.payload.data);
      }

      // Fetch additional pages if needed
      for (let i = 2; i <= (dataPage1.payload.totalPages || 1); i++) {
        const dataPage = await getAssets(displayMode, i);

        if (dataPage.isSuccess) {
          data.push(...dataPage.payload.data);
        }
      }

      if (data.length > 0) {
        exportToExcel(
          data.map((locationAsset) => {
            const arrivedAt = dayjs(locationAsset.dto.dateArrivedAtLocation);
            return {
              AssetType: locationAsset.dto.assetTypeName,
              AssetCode: locationAsset.dto.assetCode,
              DateArrived: arrivedAt.format('YYYY-MM-DD'),
              TimeArrived: arrivedAt.format('HH:mm:ss'),
            };
          }),
          `assets_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`,
        );
      }
    }
  }

  return (
    <div className="flex h-full flex-1 flex-grow  max-w-[1920px] ">
      <div className="flex flex-col h-full w-full">
        <div className="flex justify-between items-center">
          <FilterBar showAsPopover onSearch={handleSearch} buttonSize={'small'} setMinHeight={false} initialFilterValues={filters.current}>
            <div className="flex flex-col gap-y-2">
              <div className="w-72">
                <AssetTypesFilter />
              </div>
            </div>

            <FilterBarSearchButton />
          </FilterBar>

          <div className="text-xs flex items-center scale-75 origin-right">
            <LocationHierarchyToggleButton displayMode={displayMode} reverseOrder onChange={setDisplayMode} />
          </div>
        </div>
        <AssetsAtLocationGrid
          data={assetData?.data}
          isError={assetIsError}
          isLoading={assetIsLoading}
          onAssetsMoved={onAssetsMoved}
          onSortChange={onSortChange}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          sortOption={filters.current.sortOption}
          sortDirection={filters.current.sortDirection}
          onExportToExcelClicked={onExportToExcelClicked}
        ></AssetsAtLocationGrid>

        <div className="flex items-center gap-x-4">
          <PaginationControls
            isLoading={assetIsLoading}
            totalPageCount={assetData?.totalPages ?? 1}
            currentPage={filters.current.pageNumber ?? 1}
            totalElements={assetData?.totalElements ?? 0}
            onChange={onPageChanged}
          />
          <PagedResultDataText data={assetData} name={t('asset', { count: assetData?.totalElements ?? 0 })} />
        </div>
      </div>
    </div>
  );
};
