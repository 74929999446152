import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect, useState } from 'react';
import { TimelineItem } from '@/components/timeline/TimelineItem';
import { TimelineItemContentRight } from '@/components/timeline/TimelineItemContentRight';
import { TimelineItemContentLeft } from '@/components/timeline/TimelineItemContentLeft';
import TimelineItemCenterPathAndIcon from '@/components/timeline/TimelineItemCenterPathAndIcon';
import { Timeline } from '@/components/timeline/Timeline';
import { LoginRounded, LogoutRounded, PlaceRounded } from '@mui/icons-material';
import { useDate } from '@/hooks/useDate';
import { EventHistoryMap } from '@/modules/map/components/EventHistoryMap';
import { Link, Skeleton } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { assetService } from '../../api/assets/asset.service';
import { EventMapBreadcrumbDTO } from '../../api/assets/asset.contracts';
import FilterBar from '@/components/filterbar/FilterBar';
import DateFilter from '@/components/filterbar/filters/DateFilter';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import dayjs from 'dayjs';
import { useTranslation } from '@/lib';
import { movementService } from '@/modules/movements/api/movements.service';
import { TimeLineModel } from '@/modules/movements/types/TimelineModel';

interface TimelineItem {
  locationId: number;
  locationName: string;
  firstEventDate: Date;
  lastEventDate?: Date;
}

export const AssetHistoryTab: FunctionComponent = () => {
  const { assetId } = useParams();
  if (!assetId) {
    return;
  }

  const { t } = useTranslation();

  const assetIdNumber = assetId ? Number.parseInt(assetId) : 0;

  const initialDateFrom = dayjs().subtract(1, 'month');
  const initialDateTo = dayjs();

  const initialFilterValues: FilterValues = {
    dateFrom: initialDateFrom,
    dateTo: initialDateTo,
  };

  const {
    data: timelineData,
    isLoading: isLoading,
    isError: isError,
    fetchData: fetchTimeline,
    setApiCallArg,
  } = useApiCall<TimeLineModel[]>(() =>
    movementService.getTimeline(
      assetIdNumber,
      (initialFilterValues[FilterFieldName.dateFrom] ?? initialDateFrom).toISOString(),
      (initialFilterValues[FilterFieldName.dateTo] ?? initialDateTo).toISOString(),
    ),
  );

  const {
    data: breadcrumbData,
    isLoading: isLoadingBreadcrumbData,
    isError: isBreadCrumbDataError,
    fetchData: fetchBreadcrumbData,
    setApiCallArg: setBreadcrumbApiCallArg,
  } = useApiCall<EventMapBreadcrumbDTO[]>(() =>
    assetService.getBreadcrumbs({
      assetId: assetIdNumber,
      dateFrom: initialFilterValues[FilterFieldName.dateFrom],
      dateTo: initialFilterValues[FilterFieldName.dateTo],
    }),
  );

  const [timeline, setTimeline] = useState<TimelineItem[]>([]);
  const { formatDateTimestampShort, formatDuration } = useDate();

  const createTimeline = (timelineItems: TimeLineModel[]): TimelineItem[] => {
    const timeline: TimelineItem[] = [];
    if (!timelineItems || timelineItems.length === 0) {
      return timeline;
    }

    for (let i = 0; i < timelineItems.length; i++) {
      const item = timelineItems[i];

      timeline.push({
        locationId: item.location.id,
        locationName: item.location.name,
        firstEventDate: item.enterEvent.occurredAt,
        lastEventDate: item.leaveEvent?.occurredAt,
      });
    }

    return timeline.reverse();
  };

  useEffect(() => {
    if (timelineData !== undefined && timelineData.length > 0) {
      setTimeline(createTimeline(timelineData));
    }
  }, [timelineData]);

  function onFilterChange(filterValues: FilterValues) {
    console.log(JSON.stringify(filterValues));
    setApiCallArg(() =>
      movementService.getTimeline(
        assetIdNumber,
        (filterValues[FilterFieldName.dateFrom] ?? initialDateFrom)?.toISOString(),
        (filterValues[FilterFieldName.dateTo] ?? initialDateTo)?.toISOString(),
      ),
    );

    setBreadcrumbApiCallArg(() =>
      assetService.getBreadcrumbs({
        assetId: assetIdNumber,
        dateFrom: filterValues.dateFrom,
        dateTo: filterValues.dateTo,
      }),
    );
  }

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      <div className=" flex flex-1 h-full ">
        <div className="flex w-96 grow flex-col mr-4 ">
          <div className=" pb-2 border-b mb-2 dark:border-b-gray-500">
            <FilterBar onChange={onFilterChange} initialFilterValues={initialFilterValues}>
              <div className="w-44">
                <DateFilter label="Date From" filterField={FilterFieldName.dateFrom} />
              </div>
              <div className="w-44">
                <DateFilter label="Date To" filterField={FilterFieldName.dateTo} />
              </div>
            </FilterBar>
          </div>
          <div className="overflow-y-auto">
            {isLoading ? (
              <div className="flex flex-col gap-y-2">
                {Array(12)
                  .fill(1)
                  .map((val, i) => (
                    <Skeleton variant="rounded" height={80} key={i} />
                  ))}
              </div>
            ) : !timeline || timeline.length == 0 ? (
              <div className="flex justify-center mt-4">{t('no_events_found')}.</div>
            ) : (
              // </div>
              <Timeline>
                {timeline?.map((event, i, arr) => (
                  <TimelineItem key={i} isFirst={i === 0} isLast={i === arr.length - 1}>
                    <TimelineItemContentLeft>
                      <div className="flex items-center text-sm font-bold ">
                        {formatDateTimestampShort(event.lastEventDate)}
                        {event.lastEventDate ? (
                          <LogoutRounded fontSize="inherit" className="ml-1" color="error" />
                        ) : (
                          <PlaceRounded fontSize="inherit" className="ml-1" color="secondary" />
                        )}
                      </div>
                      <div className="flex items-center text-xs">
                        {formatDateTimestampShort(event.firstEventDate)}
                        <LoginRounded fontSize="inherit" className="ml-1 mr-0.5" color="success" />
                      </div>
                    </TimelineItemContentLeft>
                    <TimelineItemCenterPathAndIcon
                      icon={<PlaceRounded fontSize="small" />}
                      // onClick={() => onTimeLineItemClicked(event)}
                    ></TimelineItemCenterPathAndIcon>
                    <TimelineItemContentRight>
                      <div className="flex flex-col">
                        <Link
                          to={{ pathname: `/app/locations/${event.locationId}` }}
                          target="_blank"
                          component={RouterLink}
                          underline="hover"
                          color={'inherit'}
                          className="text-sm font-bold"
                        >
                          {event.locationName}
                        </Link>
                        <div className="text-xs">
                          {t('for')}{' '}
                          {event.lastEventDate
                            ? formatDuration(event.firstEventDate, event.lastEventDate)
                            : formatDuration(event.firstEventDate, new Date())}
                        </div>
                      </div>
                    </TimelineItemContentRight>
                  </TimelineItem>
                ))}
              </Timeline>
            )}
          </div>
        </div>
        <div className="00 h-full w-full ">
          <EventHistoryMap
            markers={
              breadcrumbData?.map((ev) => {
                return {
                  lat: ev.gpsLat,
                  lng: ev.gpsLong,
                  eventId: ev.eventId,
                };
              }) ?? []
            }
          />
        </div>
      </div>
    </div>
  );
};
